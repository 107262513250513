import React, { useContext, useEffect, useState } from "react";
import { AppDataContext } from "@src/components/AppDataContext";
import SEO from "../components/SEO";
import PostHeroSection from "@src/components/partials/mentors/PostHeroSection";
import VirtualEventsSection from "@src/components/partials/mentors/VirtualEventsSection";
import MentorSpotlightSection from "@src/components/partials/mentors/MentorSpotlightSection";
import CommunityCornerSection from "@src/components/partials/mentors/CommunityCornerSection";
import VIPResourcesSection from "@src/components/partials/mentors/VIPResourcesSection";
import DecorationPackSection from "@src/components/partials/mentors/DecorationPackSection";
import { graphql, navigate, useStaticQuery } from "gatsby";
import { TranslationContext } from "@src/components/translation/TranslationContext";
import { logEvent } from "@src/utils/logClient";
import { useLocation } from "@reach/router";
import CardSection from "@src/components/partials/mentorpdkit/CardSection";
import { defaultNormalizeStaticQueryResult } from "@src/utils/normalize-static-query-result";
import { getRelativePath } from "@src/utils/routes";

const PageMentors: React.FC = () => {
  const data = useStaticQuery(graphql`
    {
      directus {
        page_mentors {
          hero_animation_photostack_image {
            id
            filename_disk
          }
          hero_animation_bg_image {
            id
            filename_download
            filename_disk
          }
          hero_animation_mojo_image {
            id
            filename_download
            filename_disk
          }
          hero_animation_mojofriend_image {
            id
            filename_download
            filename_disk
          }
          hero_join_button_url
          hero_video_youtube_id
          hero_video_thumb_image {
            filename_disk
          }
          post_hero_accept_button_url
          post_hero_complete_button_url
          post_hero_animation_bg_image {
            id
            filename_download
            filename_disk
          }
          post_hero_animation_mojo_image {
            id
            filename_download
            filename_disk
          }
          post_hero_animation_envelope1_image {
            id
            filename_download
            filename_disk
          }
          post_hero_animation_envelope2_image {
            id
            filename_download
            filename_disk
          }
          post_hero_animation_envelope3_image {
            id
            filename_download
            filename_disk
          }
          post_hero_animation_envelope4_image {
            id
            filename_download
            filename_disk
          }
          whats_inside_animation1_image {
            id
            filename_download
            filename_disk
          }
          whats_inside_animation2_shelves_image {
            id
            filename_download
            filename_disk
          }
          whats_inside_animation2_laddermojo_image {
            id
            filename_download
            filename_disk
          }
          virtual_events
          virtual_events_calendar_image {
            id
            filename_download
            filename_disk
          }
          virtual_events_calendar_alt
          virtual_events_animation1_bg_image {
            id
            filename_download
            filename_disk
          }
          virtual_events_animation1_paperplane_image {
            id
            filename_download
            filename_disk
          }
          virtual_events_animation1_lego_image {
            id
            filename_download
            filename_disk
          }
          virtual_events_animation2_bg_image {
            id
            filename_download
            filename_disk
          }
          virtual_events_animation2_pencil_image {
            id
            filename_download
            filename_disk
          }
          community_corner_link_url
          mentors
          monthly_mentor_super_image {
            id
            filename_download
            filename_disk
          }
          monthly_mentor_friend_1_image {
            id
            filename_download
            filename_disk
          }
          monthly_mentor_friend_2_image {
            id
            filename_download
            filename_disk
          }
          monthly_mentor_friend_3_image {
            id
            filename_download
            filename_disk
          }
          monthly_mentor_mojo_image {
            id
            filename_download
            filename_disk
          }
          vip_resources_animation1_bg_image {
            id
            filename_download
            filename_disk
          }
          vip_resources_animation1_coffeemug_image {
            id
            filename_download
            filename_disk
          }
          vip_resources
          vip_resources_animation2_bg_image {
            id
            filename_download
            filename_disk
          }
          vip_resources_animation2_frontbox_image {
            id
            filename_download
            filename_disk
          }
          vip_resources_animation2_donut_image {
            id
            filename_download
            filename_disk
          }
          decoration_pack_baloon_left_image {
            id
            filename_disk
          }
          decoration_pack_baloon_right_image {
            id
            filename_disk
          }
          decoration_pack_button_url
          cta_1_button_url
          cta_2_button_url
        }
        page_mentor_pd_kit_2024 {
          a_la_carte {
            card_title
            text
            image {
              filename_disk
            }
            logging_id
            download_url
          }
        }
        page_mentors_southern_hemisphere {
          southern_hemisphere_accept_button_url
          southern_hemisphere_complete_button_url
        }
        page_careers {
          brand_love_images
          brand_love_tweets
        }
      }
    }
  `);

  defaultNormalizeStaticQueryResult(data);

  const {
    directus: {
      page_mentors: {
        hero_join_button_url,
        post_hero_accept_button_url,
        post_hero_complete_button_url,
        post_hero_animation_bg_image,
        post_hero_animation_mojo_image,
        post_hero_animation_envelope1_image,
        post_hero_animation_envelope2_image,
        post_hero_animation_envelope3_image,
        post_hero_animation_envelope4_image,
        virtual_events,
        virtual_events_calendar_image,
        virtual_events_calendar_alt,
        virtual_events_animation1_bg_image,
        virtual_events_animation1_paperplane_image,
        virtual_events_animation1_lego_image,
        virtual_events_animation2_bg_image,
        virtual_events_animation2_pencil_image,
        community_corner_link_url,
        mentors,
        monthly_mentor_super_image,
        monthly_mentor_friend_1_image,
        monthly_mentor_friend_2_image,
        monthly_mentor_friend_3_image,
        monthly_mentor_mojo_image,
        vip_resources_animation1_bg_image,
        vip_resources_animation1_coffeemug_image,
        vip_resources,
        vip_resources_animation2_bg_image,
        vip_resources_animation2_frontbox_image,
        vip_resources_animation2_donut_image,
        decoration_pack_baloon_left_image,
        decoration_pack_baloon_right_image,
        decoration_pack_button_url,
      },
      page_mentor_pd_kit_2024: { a_la_carte },
      page_careers: { brand_love_images, brand_love_tweets },
      page_mentors_southern_hemisphere: {
        southern_hemisphere_accept_button_url,
        southern_hemisphere_complete_button_url,
      },
    },
  } = data;

  const { translate } = useContext(TranslationContext);
  const t = (path) => {
    return translate(`directus.page_mentors.${path}`);
  };

  const appData = useContext(AppDataContext);

  const [user, setUser] = useState("");

  useEffect(() => {
    if (appData.data.userData && !appData.data.mentorApplicationEligibility) {
      appData.services.getMentorApplicationEligibility();
    }
    if (appData.data.userData && appData.data.userData.type === "teacher") {
      if (appData.data.userData?.isMentor) {
        setUser("teacherMentor");
      } else {
        setUser("teacher");
      }
    } else if (appData.data.userData) {
      setUser("notTeacher");
    }
  }, [appData.data, appData.services]);

  const location = useLocation();
  const decodedSearch = decodeURIComponent(location.search);

  const searchParams = new URLSearchParams(decodedSearch);

  const openJoinTheClubModal = searchParams.has("joinTheClub") || searchParams.has("joinTheClub/");

  const acceptButtonURL = post_hero_accept_button_url;
  const completeButtonURL = post_hero_complete_button_url;

  useEffect(() => {
    if (openJoinTheClubModal) {
      navigate(getRelativePath("/become-mentor-application"));
    }
  }, [openJoinTheClubModal]);

  const PostHeroSectionProps = {
    post_hero_accept_button_url: acceptButtonURL,
    post_hero_complete_button_url: completeButtonURL,
    post_hero_animation_bg_image: post_hero_animation_bg_image.file.publicURL,
    post_hero_animation_mojo_image: post_hero_animation_mojo_image.file.publicURL,
    post_hero_animation_envelope1_image: post_hero_animation_envelope1_image.file.publicURL,
    post_hero_animation_envelope2_image: post_hero_animation_envelope2_image.file.publicURL,
    post_hero_animation_envelope3_image: post_hero_animation_envelope3_image.file.publicURL,
    post_hero_animation_envelope4_image: post_hero_animation_envelope4_image.file.publicURL,
  };

  const handleDownloadButton = (eventId: string) => {
    logEvent({
      eventName: `web.external.mentor-pd-2024.open_modal.${eventId}`,
      eventValue: window.location.href,
    });
    const card = a_la_carte.find((card) => card.logging_id === eventId);
    if (card) {
      window.open(card.download_url, "_blank");
    }
  };

  const fileUrlPrefix = "https://static.classdojo.com/uploads/";

  const cardSectionProps = {
    heading: "directus.page_mentor_pd_kit_2024.cards_section_heading",
    subheading: "directus.page_mentor_pd_kit_2024.cards_section_subheading",
    cards: a_la_carte.map((card) => ({
      image: card.image ? fileUrlPrefix + card.image.filename_disk : null,
      title: card.card_title,
      description: card.text,
      downloadUrl: card.download_url,
      eventId: card.logging_id,
    })),
    downloadAction: handleDownloadButton,
    isLoggedIn: user === "teacherMentor",
    setEmailAction: () => {},
  };

  const VirtualEventsSectionProps = {
    virtual_events,
    virtual_events_calendar_image: virtual_events_calendar_image.file.publicURL,
    virtual_events_calendar_alt,
    virtual_events_animation1_bg_image: virtual_events_animation1_bg_image.file.publicURL,
    virtual_events_animation1_paperplane_image: virtual_events_animation1_paperplane_image.file.publicURL,
    virtual_events_animation1_lego_image: virtual_events_animation1_lego_image.file.publicURL,
    virtual_events_animation2_bg_image: virtual_events_animation2_bg_image.file.publicURL,
    virtual_events_animation2_pencil_image: virtual_events_animation2_pencil_image.file.publicURL,
  };

  const MonthlyMentorSectionProps = {
    monthly_mentor_super_image: monthly_mentor_super_image.file.publicURL,
    monthly_mentor_friend_1_image: monthly_mentor_friend_1_image.file.publicURL,
    monthly_mentor_friend_2_image: monthly_mentor_friend_2_image.file.publicURL,
    monthly_mentor_friend_3_image: monthly_mentor_friend_3_image.file.publicURL,
    monthly_mentor_mojo_image: monthly_mentor_mojo_image.file.publicURL,
  };

  const LoggedMentorSpotlightSectionProps = {
    mentors,
    isMentor: true,
  };

  const LoggedCommunityCornerSectionProps = {
    brand_love_images,
    brand_love_tweets,
    BGColor: true,
    community_corner_link_url,
  };

  const VIPResourcesSectionProps = {
    vip_resources,
    vip_resources_animation1_bg_image: vip_resources_animation1_bg_image.file.publicURL,
    vip_resources_animation1_coffeemug_image: vip_resources_animation1_coffeemug_image.file.publicURL,
    vip_resources_animation2_bg_image: vip_resources_animation2_bg_image.file.publicURL,
    vip_resources_animation2_frontbox_image: vip_resources_animation2_frontbox_image.file.publicURL,
    vip_resources_animation2_donut_image: vip_resources_animation2_donut_image.file.publicURL,
  };

  const CTASectionProps = {
    hero_join_button_url,
    user,
  };

  const DecorationPackSectionProps = {
    decoration_pack_baloon_left_image: decoration_pack_baloon_left_image.file.publicURL,
    decoration_pack_baloon_right_image: decoration_pack_baloon_right_image.file.publicURL,
    decoration_pack_button_url,
  };

  useEffect(() => {
    logEvent({
      eventName: "web.external_page.mentors.pageview",
      eventValue: window.location.href,
    });
  }, []);

  return (
    <>
      <SEO
        title="ClassDojo Mentors"
        description="Become a ClassDojo mentor!"
        twitter={{
          card: "summary_large_image",
          site: "@classdojo",
          creator: "@classdojo",
          title: "ClassDojo Mentors",
          description: "Become a ClassDojo mentor!",
        }}
      />
      {user === "teacherMentor" ? (
        <>
          <PostHeroSection {...PostHeroSectionProps} />
          <VirtualEventsSection {...VirtualEventsSectionProps} />
          <CardSection {...cardSectionProps} />
          <MentorSpotlightSection {...LoggedMentorSpotlightSectionProps} />
          <CommunityCornerSection {...LoggedCommunityCornerSectionProps} />
          <VIPResourcesSection {...VIPResourcesSectionProps} />
          <DecorationPackSection {...DecorationPackSectionProps} />
        </>
      ) : (
        <VirtualEventsSection {...VirtualEventsSectionProps} />
      )}
    </>
  );
};

export default PageMentors;
